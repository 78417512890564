import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicDialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-print-qr',
  templateUrl: './print-qr.component.html',
  styleUrls: ['./print-qr.component.css']
})
export class PrintQRComponent implements OnInit {

  qrCode: any;
  header: any;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private _router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.header = this.config.data.obj.header;
    this.qrCode = this.config.data.obj.qrcode;
  }

  print(){
    var printContents = document.getElementById('printDiv').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // this._router.navigate(['/payment/details']);
  }

}
