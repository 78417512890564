import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicDialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { SnapshotsService } from 'src/app/shared/services/snapshots.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  snapshotForm: FormGroup;
  newsnapshotForm: FormGroup;
  // snapshotFilterForm: FormGroup;
  
  printObject : any;
  weeklySnapshots : any;
  snapshotDates : any;
  dayTotalWeek : any;
  headers : any;
  weeklyTotalPlWork : any;
  weeklyTotalAcWork : any;

  filterValues : any;
  weeklyDates: any;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private _router: Router, private fb: FormBuilder, private snapshotService: SnapshotsService) { }

  ngOnInit() {
    this.buildForm();
    // this.buildForm2();
    this.printObject = this.config.data.obj;
    // this.weeklySnapshots = this.config.data.obj.weeklySnapshots;
    this.snapshotDates = this.config.data.obj.snapshotDates;
    this.dayTotalWeek = this.config.data.obj.dayTotalWeek;
    this.headers = this.config.data.obj.headers;
    this.weeklyTotalPlWork = this.config.data.obj.weeklyTotalPlWork;
    this.weeklyTotalAcWork = this.config.data.obj.weeklyTotalAcWork;

    this.filterValues = this.config.data.obj.filterValues;

    this.getPrintableSnpData();

    console.log(this.filterValues);
    console.log(this.headers);
    // console.log(this.weeklySnapshots);
    console.log(this.snapshotDates);
    console.log(this.printObject);
  }

  print(){
    var printContents = document.getElementById('printDiv').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // this._router.navigate(['/payment/details']);
  }

  getPrintableSnpData(){
    this.snapshotService.printSnapshots(this.filterValues).subscribe( res =>{
      console.log(res);
      this.weeklySnapshots = res['weeklySnapshots'];
      this.weeklyDates = res['weekdates'];
      // console.log(this.weeklyDates);
      // console.log(this.weeklySnapshots);
    })
  }

  buildForm(){
    this.snapshotForm = this.fb.group({
      id: ["0"],
      taskId: [""],
      employeeId: [null],
      remarks: [""],
      snapValues: [""],
      status: ["1"],
    });

    this.newsnapshotForm = this.fb.group({
      id: ["0"],
      prj_id: ["", Validators.required],
      taskId: ["", Validators.required],
      sprintId: [""],
      employeeId: [null, Validators.required],
      remarks: [""],
      snapValues: [""],
      status: ["1"],
    });
  }

  // buildForm2() {
  //   this.snapshotFilterForm = this.fb.group({
  //     id: ["0"],
  //     prj_id: [""],
  //     sprint_id: [""],
  //     weekStartDate: [""],
  //     weekEndDate: [""],
  //     assigneeid: [""],
  //     status: ["1"],
  //   });
  // }

}
