import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { tap, map, retry, catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { Tasks } from "../models/task.model";
import { Sprints } from "../models/sprint.model";
import { Snapshots } from "../models/snapshot.model";

@Injectable({
  providedIn: "root",
})
export class SnapshotsService {
  baseUrl = environment.apiserver;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };
  constructor(private httpClient: HttpClient) {}

  getTask(id?: number, page?: number): Observable<Tasks[]> {
    let url = `${this.baseUrl}/alltasks`;

    if (id > 0) {
      url = `${this.baseUrl}/alltasks/` + id;
    }

    return this.httpClient
      .get<Tasks[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getActlHr(id: number): Observable<Snapshots[]> {
    let url = `${this.baseUrl}/actlefft/` + id;

    return this.httpClient
      .get<Snapshots[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  getSprint(id?: number, page?: number): Observable<Sprints[]> {
    let url = `${this.baseUrl}/allsprint`;

    if (id > 0) {
      url = `${this.baseUrl}/allsprint/` + id;
    }

    return this.httpClient
      .get<Sprints[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  addSnapshot(data: any): Observable<Snapshots> {
    let url = `${this.baseUrl}/addsnap`;

    return this.httpClient
      .post<Snapshots>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllSnapshots(): Observable<Snapshots[]> {
    let url = `${this.baseUrl}/allsnapshots`;

    return this.httpClient
      .get<Snapshots[]>(url)
      .pipe(retry(1), catchError(this.handleError));
  }

  filterAllSnapshots(data: any, page?: number): Observable<Snapshots[]> {
    let url = `${this.baseUrl}/allsnapshots/filter`;

    if (page > 0) {
      url = `${this.baseUrl}/allsnapshots/filter?page=` + page;
    }
    return this.httpClient
      .post<Snapshots[]>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  printSnapshots(data: any, page?: number): Observable<Snapshots[]> {
    let url = `${this.baseUrl}/allsnapshots/print`;

    if (page > 0) {
      url = `${this.baseUrl}/allsnapshots/print?page=` + page;
    }
    return this.httpClient
      .post<Snapshots[]>(url, JSON.stringify(data), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
