import * as moment from "moment";
import { Employee } from "./Employee.model";

export class User {
  public id: number;
  public name: string;
  public email: string;
  public password: string;
  public image: string;
  public level: string;

  public role: string;
  public contact: string;
  public profile?: Employee;
  public token: string;
  public tokenexpire: moment.Moment;
}
