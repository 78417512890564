import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/shared/services/company.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { User } from 'src/app/shared/models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { SelectItem } from 'primeng/api/selectitem';
import { BroadcastMessage } from 'src/app/shared/models/broadcast-message.model';
import { PageMeta } from 'src/app/shared/core/page_meta';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-message-broadcast',
  templateUrl: './message-broadcast.component.html',
  styleUrls: ['./message-broadcast.component.css']
})
export class MessageBroadcastComponent implements OnInit {
  userSession: User;

  newBroadcastForm: FormGroup;

  isSaving: boolean = false;
  today: string;
  statusList: SelectItem[];
  broadcastList: BroadcastMessage[];
  
  meta: PageMeta;
  filterOn: boolean = false;

  constructor(private companyService: CompanyService, private authService: AuthService, private messageService: MessageService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.statusList = [
      { label: "Select Status", value: null, disabled: true },
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ];
    this.getUserDetails();
    this.buildForm();
  }

  getUserDetails(){
    this.authService.user.subscribe((data) => {
      this.userSession = data;
      console.log(this.userSession);
      this.getAllMessages(1);
    });
  }

  buildForm(){
    this.newBroadcastForm = this.fb.group({
      id: [0],
      message: ["", Validators.required],
      composer_emp_id: [],
      company_id: [],
      date: ["", Validators.required],
      status: [, Validators.required],
      archived: [1]
    });
  }

  saveBroadcast(){
    this.isSaving = true;

    this.today = formatDate(new Date(), "yyyy-MM-dd", "en");

    this.newBroadcastForm.get('composer_emp_id').patchValue(this.userSession.profile.id);
    this.newBroadcastForm.get('company_id').patchValue(this.userSession.profile.company_id);
    this.newBroadcastForm.get('date').patchValue(this.today);
    
    if (this.newBroadcastForm.invalid){
      this.isSaving = false;
      // window.alert("Please fill all required fields");
      this.messageService.add({
        severity: "error",
        summary: "Error:",
        detail: "Please fill all required fields",
      });
      return;
    }

    console.log(this.newBroadcastForm.value);
    this.companyService.saveBroadcastMessage(this.newBroadcastForm.value).subscribe(res =>{
      // window.alert(res['message']);
      this.messageService.add({
        severity: "success",
        summary: "Success:",
        detail: res['message'],
      });
      this.buildForm();
      this.getUserDetails();
      this.isSaving = false;
    }, err =>{
      // window.alert("Failed to save record");
      this.messageService.add({
        severity: "error",
        summary: "Error:",
        detail: "Failed to save record",
      });
      this.isSaving = false;
    });    
  }

  getAllMessages(page: number){
    this.companyService.getAllBroadcastMsgs(page).subscribe(res =>{
      this.broadcastList = res['data'];
      this.meta = res['meta'];
      console.log(res);
    }, err =>{
      console.log(err);
    })
  }

  editBroadcast(value){
    this.newBroadcastForm.patchValue(value);
    // this.isEdi
    console.log(this.newBroadcastForm.value);
  }

  paginate(event) {
    console.log(event);
    debugger;
    if (this.filterOn == true) {
      debugger;
      //this.searchSales(event.page +1);
    } else {
      // this.getAllTasksList(0, event.page +1);
      this.getAllMessages(event.page + 1);
    }

    event.rows = 3;
  }

}
