import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvoiceRoutingModule } from './invoice-routing.module';
import { InvoiceComponent } from './invoice.component';

import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ToastModule } from "primeng/toast";
import { PaginatorModule } from "primeng/paginator";
import { AutoCompleteModule } from "primeng/autocomplete";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { TableModule } from "primeng/table";
import { TooltipModule } from "ng2-tooltip-directive";
import { DynamicDialogModule } from 'primeng/dynamicDialog';


@NgModule({
  declarations: [InvoiceComponent],
  imports: [
    CommonModule,
    InvoiceRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextareaModule,
    ToastModule,
    PaginatorModule,
    AutoCompleteModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    CalendarModule,
    TableModule,
    TooltipModule,
    DynamicDialogModule
  ]
})
export class InvoiceModule { }
